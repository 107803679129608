@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url("https://use.typekit.net/tck7qll.css");


@font-face {
    font-family:"futura-pt";
    src:url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
    }
    
    @font-face {
    font-family:"futura-pt";
    src:url("https://use.typekit.net/af/309dfe/000000000000000000010091/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/309dfe/000000000000000000010091/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/309dfe/000000000000000000010091/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
    }
    
    @font-face {
    font-family:"futura-pt";
    src:url("https://use.typekit.net/af/eb729a/000000000000000000010092/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/eb729a/000000000000000000010092/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/eb729a/000000000000000000010092/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
    }
    
    @font-face {
    font-family:"futura-pt";
    src:url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
    }
    
    @font-face {
    font-family:"futura-pt";
    src:url("https://use.typekit.net/af/cf3e4e/000000000000000000010095/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/cf3e4e/000000000000000000010095/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/cf3e4e/000000000000000000010095/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
    }
    
    @font-face {
    font-family:"futura-pt";
    src:url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
    }
    
    @font-face {
    font-family:"futura-pt";
    src:url("https://use.typekit.net/af/0c71d1/000000000000000000010097/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff2"),url("https://use.typekit.net/af/0c71d1/000000000000000000010097/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff"),url("https://use.typekit.net/af/0c71d1/000000000000000000010097/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:800;font-stretch:normal;
    }
    
    @font-face {
    font-family:"futura-pt";
    src:url("https://use.typekit.net/af/c4c302/000000000000000000012192/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/c4c302/000000000000000000012192/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/c4c302/000000000000000000012192/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
    }
    
    @font-face {
    font-family:"futura-pt-bold";
    src:url("https://use.typekit.net/af/053fc9/00000000000000003b9af1e4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/053fc9/00000000000000003b9af1e4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/053fc9/00000000000000003b9af1e4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
    }
    
    @font-face {
    font-family:"futura-pt-bold";
    src:url("https://use.typekit.net/af/72575c/00000000000000003b9af1e5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/72575c/00000000000000003b9af1e5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/72575c/00000000000000003b9af1e5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
    }
    
    @font-face {
    font-family:"ibm-plex-sans";
    src:url("https://use.typekit.net/af/6eb20e/000000000000000077359422/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/6eb20e/000000000000000077359422/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/6eb20e/000000000000000077359422/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
    }
    
    @font-face {
    font-family:"ibm-plex-sans";
    src:url("https://use.typekit.net/af/89996a/000000000000000077359445/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/89996a/000000000000000077359445/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/89996a/000000000000000077359445/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
    }
    
    @font-face {
    font-family:"ibm-plex-sans";
    src:url("https://use.typekit.net/af/0626f2/000000000000000077359441/30/l?subset_id=2&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/0626f2/000000000000000077359441/30/d?subset_id=2&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/0626f2/000000000000000077359441/30/a?subset_id=2&fvd=n5&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
    }
    
    @font-face {
    font-family:"ibm-plex-sans";
    src:url("https://use.typekit.net/af/fb80cb/000000000000000077359455/30/l?subset_id=2&fvd=i6&v=3") format("woff2"),url("https://use.typekit.net/af/fb80cb/000000000000000077359455/30/d?subset_id=2&fvd=i6&v=3") format("woff"),url("https://use.typekit.net/af/fb80cb/000000000000000077359455/30/a?subset_id=2&fvd=i6&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:600;font-stretch:normal;
    }
    
    @font-face {
    font-family:"ibm-plex-sans";
    src:url("https://use.typekit.net/af/f1c6f0/00000000000000007735945d/30/l?subset_id=2&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/f1c6f0/00000000000000007735945d/30/d?subset_id=2&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/f1c6f0/00000000000000007735945d/30/a?subset_id=2&fvd=n6&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
    }
    
    @font-face {
    font-family:"ibm-plex-sans";
    src:url("https://use.typekit.net/af/e8cd6e/00000000000000007735945f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/e8cd6e/00000000000000007735945f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/e8cd6e/00000000000000007735945f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
    }
    
    @font-face {
    font-family:"ibm-plex-sans";
    src:url("https://use.typekit.net/af/2c6c03/000000000000000077359463/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/2c6c03/000000000000000077359463/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/2c6c03/000000000000000077359463/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
    }
    
    @font-face {
    font-family:"ibm-plex-sans";
    src:url("https://use.typekit.net/af/53fac9/00000000000000007735946b/30/l?subset_id=2&fvd=n8&v=3") format("woff2"),url("https://use.typekit.net/af/53fac9/00000000000000007735946b/30/d?subset_id=2&fvd=n8&v=3") format("woff"),url("https://use.typekit.net/af/53fac9/00000000000000007735946b/30/a?subset_id=2&fvd=n8&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:800;font-stretch:normal;
    }
    
    @font-face {
    font-family:"ibm-plex-sans-condensed";
    src:url("https://use.typekit.net/af/fc4c10/000000000000000077359438/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/fc4c10/000000000000000077359438/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/fc4c10/000000000000000077359438/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
    }
    
    @font-face {
    font-family:"ibm-plex-sans-condensed";
    src:url("https://use.typekit.net/af/1dfb4f/00000000000000007735943c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/1dfb4f/00000000000000007735943c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/1dfb4f/00000000000000007735943c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
    }
    
    @font-face {
    font-family:"ibm-plex-sans-condensed";
    src:url("https://use.typekit.net/af/80a0e8/00000000000000007735945b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/80a0e8/00000000000000007735945b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/80a0e8/00000000000000007735945b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
    }
    
    @font-face {
    font-family:"ibm-plex-sans-condensed";
    src:url("https://use.typekit.net/af/1e17b4/000000000000000077359460/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/1e17b4/000000000000000077359460/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/1e17b4/000000000000000077359460/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
    }

@font-face {
    font-family: 'Mont';
    src: url('../fonts/Mont-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('../fonts/Mont-Book.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('../fonts/Mont-Heavy.ttf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('../fonts/Mont-Black.ttf');
    font-weight: 900;
    font-style: normal;
}

* {
    font-family: "futura-pt", sans-serif;
    color: #003955;
}

.home-header {
    z-index: 5 !important;
    background: transparent !important;
    padding: 0 !important;
    margin-bottom: -40px !important;
}

.st-sticky-share-buttons {
    display: none !important;
}

@media (max-width: 1060px) {
    .underline-accent {
        margin-bottom: -18px;
        border-bottom: 2px solid #BE3258;
    }
}

.ais-SearchBox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ais-InfiniteHits-loadPrevious {
    display: none;
}

.ais-SearchBox-form {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ais-SearchBox-input {
    font-size: 20px;
    padding: 4px;
}

.ais-SearchBox-submit {
    display: none;
}

.ais-SearchBox-reset {
    background: rgb(250, 250, 250);
    border: 1px solid black;
    height: 32px;
    width: 32px;
}

.map-strip>div {
    width: 100%;
    height: 100%;
}

.map-strip iframe {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    background: #F8FDFF;
    overflow-x: hidden;
}

@media (max-width: 550px)
{
    .floating-whatsapp-button {
        transform: scale(0.8);
    }
}
